import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IoMdArrowDropleftCircle, IoMdInformationCircleOutline } from 'react-icons/io';
import { format } from 'date-fns';

import { currentUser } from 'state/global/currentUser';
import { activeGraphDefinition } from 'state/KubenDashboard/chartInformationModal';
import { graphXTicks } from 'state/KubenDashboard/graphs';
import useMutateFetchGraph from 'hooks/api/graph/useMutateFetchGraph';
import GraphXAxisLabelOption from 'config/graphXAxisLabelOption';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorMessage from 'components/global/UICommon/UIErrorMessage';
import DashboardChartLegend from './DashboardChartLegend';
import DashboardLineChart from './DashboardLineChart';
import DashboardBarChart from './DashboardBarChart';
import DashboardCreateGraphReport from './DashboardCreateGraphReport';
import DashboardChartOptionsDropdown from './DashboardChartOptionsDropdown';

const DashboardChartWrapper = ({
  graphDefinition,
  chartId,
  isEditLayout,
  chosenGraphRegion,
  chosenGraphFilters,
  chosenGraphPeriod,
  data: incomingData,
  useIncomingData,
  onRemove,
}) => {
  const user = useRecoilValue(currentUser);
  const setActiveGraph = useSetRecoilState(activeGraphDefinition);
  const [showLegend, setShowLegend] = useState(false);
  const [legendHoverItem, setLegendHoverItem] = useState();
  const [activeTitles, setActiveTitles] = useState([]);
  const [graphData, setGraphData] = useState(null);
  const history = useHistory();
  const {
    data: fetchedData,
    isError,
    mutate,
  } = useMutateFetchGraph(
    chartId,
    chosenGraphRegion.id,
    chosenGraphFilters.regionNodes,
    chosenGraphFilters.businessTypes,
    format(chosenGraphPeriod.startDate, 'yyyy-MM-dd'),
    format(chosenGraphPeriod.endDate, 'yyyy-MM-dd'),
  );
  const [xTickState, setXTickState] = useRecoilState(graphXTicks);
  const xTickOptions = [
    GraphXAxisLabelOption.SHORT,
    GraphXAxisLabelOption.FULL,
    GraphXAxisLabelOption.INDEX,
    GraphXAxisLabelOption.NONE,
  ];

  const getNextGraphXTickState = () => {
    const currentObj = xTickOptions.find((e) => e === xTickState);
    const currentIndex = xTickOptions.indexOf(currentObj);
    const nextIndex = currentIndex == xTickOptions.length - 1 ? 0 : currentIndex + 1;
    return xTickOptions[nextIndex];
  };

  const options = [
    {
      label: 'Gå till detaljvy',
      onClick: () => history.push('/dashboard/' + chartId),
      isVisible: history.location.pathname.endsWith('dashboard/') || history.location.pathname.endsWith('dashboard'),
    },
    {
      label: 'Visa information',
      onClick: () => setActiveGraph(graphDefinition),
      isVisible: true,
    },
    {
      label: `Visar ${xTickOptions.find((e) => e === xTickState)} x-axel`,
      onClick: () => setXTickState(getNextGraphXTickState()),
      isVisible: true,
    },
    {
      isVisible: user.superAdministrator,
      component: (
        <DashboardCreateGraphReport
          key={chartId}
          chartId={chartId}
          regionId={chosenGraphRegion.id}
          startDate={format(chosenGraphPeriod.startDate, 'yyyy-MM-dd')}
          endDate={format(chosenGraphPeriod.endDate, 'yyyy-MM-dd')}
        />
      ),
    },
  ];

  useEffect(() => {
    setGraphData(null);
  }, [incomingData]);

  useEffect(() => {
    if (incomingData) {
      setGraphData(incomingData);
    } else if (fetchedData) {
      setGraphData(fetchedData);
    }
  }, [fetchedData, incomingData]);

  useEffect(() => {
    if (chosenGraphRegion.id && !useIncomingData) {
      mutate();
    }
  }, [chosenGraphRegion, chosenGraphFilters, chosenGraphPeriod, chartId]);

  useEffect(() => {
    if (graphData) {
      setActiveTitles(graphData.titles.map((t) => t.id));
    }
  }, [graphData]);

  return (
    <div
      className={`${isEditLayout ? 'shadow cursor-grab' : ''}
     dragMe flex flex-col background relative rounded-xl group 
      graph-wrapper h-full`}
    >
      {isError ? (
        <UIErrorMessage />
      ) : graphData == null ? (
        <UILoadingModal />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex border-b border-tertiary justify-between p-5">
              <div className="flex flex-col justify-start text-left whitespace-nowrap">
                <div className="flex items-center text-base title secondary">
                  {graphDefinition.title}
                  {!isEditLayout && (
                    <IoMdInformationCircleOutline
                      className="text-xl border-tertiary opacity-70 hover:opacity-100
                    hover:scale-105 transition-all duration-150 ease-in-out ml-2"
                      onClick={() => setActiveGraph(graphDefinition)}
                    />
                  )}
                </div>
                <div className="opacity-40 text-sm whitespace-normal subtitle">{graphDefinition.preface}</div>
              </div>
              {isEditLayout ? (
                <button
                  draggable={false}
                  onClick={onRemove}
                  className="underline error text-sm p-3 bg-blue- 200 z-10
                    transition-all hover:scale-105"
                >
                  Ta bort
                </button>
              ) : (
                <DashboardChartOptionsDropdown>
                  {options
                    .filter((option) => option.isVisible)
                    .map(
                      (option, idx) =>
                        option.component || (
                          <div
                            key={idx}
                            className="w-full pl-4 h-full flex items-center"
                            onClick={option.onClick}
                          >
                            {option.label}
                          </div>
                        ),
                    )}
                </DashboardChartOptionsDropdown>
              )}
            </div>
          </div>
          <div className="flex h-full w-full overflow-hidden p-5 relative">
            {graphData.type === 'bar' ? (
              <DashboardBarChart
                chartData={graphData}
                activeTitles={activeTitles}
                legendHoverItem={legendHoverItem}
                isEdit={isEditLayout}
              />
            ) : graphData.type === 'line' ? (
              <DashboardLineChart
                chartData={graphData}
                activeTitles={activeTitles}
                isEdit={isEditLayout}
              />
            ) : (
              <>Graph not supported: {graphData.type}</>
            )}
            <IoMdArrowDropleftCircle
              className={`${isEditLayout ? '' : 'group-hover:visible'} invisible title
               w-8 h-8 absolute z-40 bottom-0 transition-all duration-50 ease-out opacity-50
                hover:opacity-100 ${showLegend ? 'right-1/2 scale-x-[-1]' : 'right-0'}`}
              onClick={() => setShowLegend(!showLegend)}
            />
            <DashboardChartLegend
              chartData={graphData}
              graphDefinition={graphDefinition}
              isVisible={showLegend}
              activeTitles={activeTitles}
              setActiveTitles={setActiveTitles}
              onDataItemHover={(dataName) => setLegendHoverItem(dataName)}
            />
          </div>
        </>
      )}
    </div>
  );
};

DashboardChartWrapper.propTypes = {
  graphDefinition: PropTypes.any,
  chartId: PropTypes.string.isRequired,
  isEditLayout: PropTypes.bool,
  chosenGraphRegion: PropTypes.object.isRequired,
  chosenGraphFilters: PropTypes.object.isRequired,
  chosenGraphPeriod: PropTypes.object.isRequired,
  data: PropTypes.object,
  useIncomingData: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default DashboardChartWrapper;
