import actions from 'api/graph';
import { useMutation } from 'react-query';

const useMutateCreateGraphDataReport = (graphId, regionId, startDate, endDate) => {
  return useMutation('useMutateCreateGraphDataReport', async () => {
    const res = await actions.createGraphReport(graphId, regionId, startDate, endDate);
    return res.data;
  });
};

export default useMutateCreateGraphDataReport;
