import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import timeRange from 'utils/KubenAssessments/timeRange';

import QuestionDropdown from '../../QuestionsPage/QuestionDropdown';

const SummaryFrequencyType = ({
  userAnswers,
  questionId,
  minValue,
  maxValue,
  handleClick,
  isEditable,
  isReadonly,
  isAnsweredExternally,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(userAnswers?.count);

  const handleUserType = (value) => {
    if (value >= minValue && value <= maxValue) {
      handleClick(questionId, 'FrequencyTypeFrequency', Number(value));
    }
  };

  return (
    <div>
      <div className="flex space-x-5 mt-1">
        <div className="flex flex-col">
          <p className="title text-[24px] font-semibold mb-3 text-left">
            {t('SummaryFrequencyType.frequencyLabel')}
            <span className="subtitle text-[18px]">
              {` (${t('QuestionFrequencyType.rangeText')} ${minValue}-${maxValue})`}
            </span>
          </p>
          <input
            className={`${
              isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''
            } h-10 pl-6 pr-2 rounded-5px outline-none border-tertiary background-secondary-add border`}
            type="number"
            value={value}
            pattern="[0-9]"
            onChange={(e) => setValue(e.target.value)}
            disabled={isReadonly}
            onBlur={() => handleUserType(value)}
          />
        </div>
        <QuestionDropdown
          value={userAnswers?.timePeriod}
          options={timeRange.timePeriods}
          actionName="FrequencyTypeTimePeriod"
          questionId={questionId}
          style="dark"
          translate={true}
          translateKey="timePeriodDropdownLabel"
          handleClick={handleClick}
          isEditable={!isEditable}
          isReadonly={isReadonly}
          isAnsweredExternally={isAnsweredExternally}
        />
      </div>
    </div>
  );
};

SummaryFrequencyType.propTypes = {
  userAnswers: PropTypes.object.isRequired,
  questionId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  isEditable: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
};

export default SummaryFrequencyType;
