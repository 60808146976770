import client from './clients/base';

const BASE_URL = 'graph';

const fetchGraphDefinitionCategories = async () => {
  return await client.get(`${BASE_URL}/graph-definition-categories/`);
};

const fetchGraphDefinitions = async () => {
  return await client.get(`${BASE_URL}/graph-definitions`);
};

const createGraphReport = async (graphId, regionId, startDate, endDate) => {
  const body = {
    graphId: graphId,
    regionId: regionId,
    graphPeriod: 'Month',
    startDate: startDate,
    endDate: endDate,
  };
  return await client.post(`${BASE_URL}/create-graph-data-report/`, body);
};

const fetchGraph = async (id, chosenRegionId, businessUnitIds, businessTypeIds, startDate, endDate) => {
  const body = {
    graphId: id,
    graphPeriod: 'Month',
    regionId: chosenRegionId,
    startDate: startDate,
    endDate: endDate,
  };

  if (businessUnitIds.length > 0) {
    body['businessUnitIds'] = businessUnitIds;
  }
  if (businessTypeIds.length > 0) {
    body['businessTypeIds'] = businessTypeIds;
  }

  return await client.post(`${BASE_URL}/graph-data/`, body);
};

const fetchDashboardGraphs = async (chosenRegionId, businessUnitIds, businessTypeIds, startDate, endDate) => {
  const body = {
    graphPeriod: 'Month',
    regionId: chosenRegionId,
    startDate: startDate,
    endDate: endDate,
  };

  if (businessUnitIds.length > 0) {
    body['businessUnitIds'] = businessUnitIds;
  }
  if (businessTypeIds.length > 0) {
    body['businessTypeIds'] = businessTypeIds;
  }

  return await client.post(`${BASE_URL}/graph-dashboard-data/`, body);
};

const fetchGraphDrillDown = async (
  id,
  page,
  chosenRegionId,
  businessUnitIds,
  businessTypeIds,
  startDate,
  endDate,
  isSorted,
  drillDownTitleKey,
  drillDownDataKey,
  drillDownQueryString,
) => {
  const body = {
    graphId: id,
    graphPeriod: 'Month',
    regionId: chosenRegionId,
    startDate: startDate,
    endDate: endDate,
    page: page,
    pageSize: 12,
    drillDownTitleKey: drillDownTitleKey,
    drillDownDataKey: drillDownDataKey,
    sortedAscending: isSorted || false,
    drillDownQueryString: drillDownQueryString,
  };

  if (businessUnitIds.length > 0) {
    body['businessUnitIds'] = businessUnitIds;
  }
  if (businessTypeIds.length > 0) {
    body['businessTypeIds'] = businessTypeIds;
  }

  return await client.post(`${BASE_URL}/graph-drilldown-data/`, body);
};

const fetchGraphRegions = async () => {
  const response = await client.get(`${BASE_URL}/graph-regions`);
  return response.data;
};

const fetchGraphFilters = async (regionId) => {
  const response = await client.get(`${BASE_URL}/graph-filters/${regionId}`);
  return response.data;
};

const actions = {
  fetchGraphDefinitionCategories,
  fetchGraphDefinitions,
  fetchGraph,
  fetchDashboardGraphs,
  fetchGraphDrillDown,
  createGraphReport,
  fetchGraphRegions,
  fetchGraphFilters,
};

export default actions;
